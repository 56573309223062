import * as Tooltip from '@radix-ui/react-tooltip';
import { Form as FinalForm } from 'react-final-form';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { z } from 'zod';

import { FieldSelect, FieldTextInput, H4, IconSpinner, PrimaryButton } from '../../../components';
import { useGetStripeAccount, useUpdateStripeAccount } from '../../../hooks/api/users';
import { useCountryCodes } from '../../../hooks/useCountryCodes';
import * as validators from '../../../util/validators';
import css from './BankAccount.module.css';
import InternationalPhoneNumberInput from 'components/InternationalPhoneNumberInput/InternationalPhoneNumberInput';
import { useMediaQueries } from 'hooks/useMediaQueries';

const bankAccountSchema = z.object({
  bankAccountCountry: z.string(),
  bankAccountCurrency: z.string(),
  bankAccountNumber: z.string(),
  bankAccountSortCode: z.string().optional(),
  country: z.string(),
  city: z.string(),
  street1: z.string(),
  street2: z.string().optional(),
  zip: z.string(),
  phoneNumber: z.string(),
});

export const BankAccount = props => {
  const isDesktop = useMediaQueries({ viewport: 'medium' });
  const countryOptions = useCountryCodes();
  const intl = useIntl();

  const { data: stripeAccount, isLoading } = useGetStripeAccount();
  const { mutateAsync: createStripeAccount, isLoading: isCreatingStripeAccount } =
    useUpdateStripeAccount();

  const isProfileComplete = stripeAccount && stripeAccount.bankAccount;

  const onSubmit = async values => {
    try {
      await createStripeAccount(values);
      toast.success('Yay! Your stripe account is now ready.');
    } catch (error: any) {
      toast.error(
        `${intl.formatMessage({ id: 'BankAccountPage.failedToSaveBankAccountDetails' })}
        ${error.message}`
      );
    }
  };

  if (isLoading) {
    return (
      <div style={{ height: 300, display: 'grid', placeContent: 'center' }}>
        <IconSpinner />
      </div>
    );
  }

  return (
    <>
      <div className={css.root}>
        <FinalForm
          onSubmit={onSubmit}
          disabled
          initialValues={
            isProfileComplete
              ? {
                  bankAccountCountry: stripeAccount.bankAccount.country || '',
                  bankAccountCurrency: stripeAccount.bankAccount.currency.toUpperCase() || '',
                  bankAccountNumber: stripeAccount.bankAccount.accountNumber
                    ? '...' + stripeAccount.bankAccount.accountNumber
                    : '',
                  bankAccountSortCode: stripeAccount.bankAccount.sortCode || '',
                  country: stripeAccount.billingAddress.country || '',
                  city: stripeAccount.billingAddress.city || '',
                  street1: stripeAccount.billingAddress.street1 || '',
                  street2: stripeAccount.billingAddress.street2 || '',
                  zip: stripeAccount.billingAddress.zip || '',
                  phoneNumber: stripeAccount.billingAddress.phoneNumber || '',
                }
              : undefined
          }
          validate={values => {
            // @ts-expect-error TS(2339) FIXME: Property 'error' does not exist on type 'SafeParse... Remove this comment to see the full error message
            const { error } = bankAccountSchema.safeParse(values);

            return getFinalFormValidationErrors(error);
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={css.form}>
              <div className={css.section}>
                <h2 className={css.sectionTitle}>
                  <FormattedMessage id="BankAccountPage.payoutDetailsFormLabel" />
                </h2>
                <FieldSelect
                  label={intl.formatMessage({ id: 'BankAccountPage.bankAccountCountryLabel' })}
                  id="bankAccountCountry"
                  name="bankAccountCountry"
                  disabled={isProfileComplete}
                >
                  <option disabled value="">
                    {intl.formatMessage({ id: 'BankAccountPage.bankAccountCountryPlaceholder' })}
                  </option>
                  {countryOptions.map(c => (
                    <option key={c.code} value={c.code}>
                      {c.name}
                    </option>
                  ))}
                </FieldSelect>
                <FieldSelect
                  label={intl.formatMessage({ id: 'BankAccountPage.bankAccountCurrencyLabel' })}
                  id="bankAccountCurrency"
                  name="bankAccountCurrency"
                  disabled={isProfileComplete}
                >
                  <option disabled value="">
                    {intl.formatMessage({ id: 'BankAccountPage.bankAccountCurrencyPlaceholder' })}
                  </option>
                  {stripeAccount?.country === 'GB' ? <option>GBP</option> : <option>EUR</option>}
                </FieldSelect>
                <FieldTextInput
                  label={intl.formatMessage({ id: 'BankAccountPage.bankAccountNumberLabel' })}
                  id="bankAccountNumber"
                  name="bankAccountNumber"
                  type="text"
                  disabled={isProfileComplete}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FieldTextInput
                    label={
                      <span
                        style={{ display: 'flex', gap: 'var(--n-size-1)', alignItems: 'center' }}
                      >
                        {isDesktop && (
                          <>
                            {intl.formatMessage({ id: 'BankAccountPage.sortCodeLabelDesktop' })}
                            <Tooltip.Provider>
                              <Tooltip.Root>
                                <Tooltip.Trigger asChild>
                                  {!isProfileComplete && (
                                    <span aria-label="Sort code info" className={css.tooltip}>
                                      ?
                                    </span>
                                  )}
                                </Tooltip.Trigger>
                                <Tooltip.Content
                                  side="top"
                                  align="center"
                                  style={{
                                    backgroundColor: 'white',
                                    color: 'black',
                                    padding: '10px',
                                    borderRadius: '4px',
                                    boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
                                  }}
                                >
                                  If you have provided an IBAN for account number, you can leave
                                  this field empty.
                                </Tooltip.Content>
                              </Tooltip.Root>
                            </Tooltip.Provider>
                          </>
                        )}
                        {!isDesktop &&
                          intl.formatMessage({ id: 'BankAccountPage.sortCodeLabelMobile' })}
                      </span>
                    }
                    id="bankAccountSortCode"
                    name="bankAccountSortCode"
                    type="text"
                    disabled={isProfileComplete}
                  />
                </div>
              </div>
              <div className={css.section}>
                <h2 className={css.sectionTitle}>
                  <FormattedMessage id="BankAccountPage.addressSectionTitle" />
                </h2>
                <FieldSelect
                  label={intl.formatMessage({ id: 'BankAccountPage.countryLabel' })}
                  id="country"
                  name="country"
                  disabled={isProfileComplete}
                >
                  <option disabled value="">
                    {intl.formatMessage({ id: 'BankAccountPage.countryPlaceholder' })}
                  </option>
                  {countryOptions.map(c => (
                    <option key={c.code} value={c.code}>
                      {c.name}
                    </option>
                  ))}
                </FieldSelect>
                <FieldTextInput
                  label={intl.formatMessage({ id: 'BankAccountPage.cityLabel' })}
                  id="city"
                  name="city"
                  type="text"
                  disabled={isProfileComplete}
                />
                <FieldTextInput
                  label={intl.formatMessage({ id: 'BankAccountPage.street1Label' })}
                  id="street1"
                  name="street1"
                  type="text"
                  disabled={isProfileComplete}
                />
                <FieldTextInput
                  label={intl.formatMessage({ id: 'BankAccountPage.street2Label' })}
                  id="street2"
                  name="street2"
                  type="text"
                  disabled={isProfileComplete}
                />
                <FieldTextInput
                  label={intl.formatMessage({ id: 'BankAccountPage.zipLabel' })}
                  id="zip"
                  name="zip"
                  type="text"
                  disabled={isProfileComplete}
                />
                <InternationalPhoneNumberInput
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  defaultCountry="GB"
                  withCountryCallingCode
                  countryCallingCodeEditable={false}
                  international
                  disabled={isProfileComplete}
                  label={intl.formatMessage({ id: 'BankAccountPage.phoneNumberLabel' })}
                  validate={validators.required(
                    intl.formatMessage({ id: 'BankAccountPage.phoneNumberRequired' })
                  )}
                />
              </div>
              <div style={{ margin: 'var(--n-size-4) 0', position: 'relative' }}>
                {isProfileComplete && (
                  <div style={{ marginBottom: 16 }}>
                    Please contact support to change your payout details
                  </div>
                )}
                <PrimaryButton
                  type="submit"
                  disabled={isProfileComplete}
                  inProgress={isCreatingStripeAccount}
                >
                  <FormattedMessage id="BankAccountPage.submitPayoutDetails" />
                </PrimaryButton>
              </div>
            </form>
          )}
        />
      </div>
    </>
  );
};

function getFinalFormValidationErrors(zodError) {
  return (zodError?.issues || []).reduce((acc, issue) => {
    acc[issue.path.join('.')] = issue.message;

    return acc;
  }, {});
}

export default BankAccount;
