import { z } from 'zod';

import { ConditionType } from 'config/configListing';
import {
  categoryOptions,
  departmentOptions,
  productSubtypeOptions,
  productTypeOptions,
} from 'containers/EditListingPage/EditListingWizard/EditListingCategoryAndSize/productTypeDefinitions';
import { useGetBrandIdOptions } from 'hooks/api/listings/useGetBrandIdOptions';
import { parseSearchQueryParams, useQueryParams } from 'hooks/useQueryParams';
import { DEFAULT_SORTING } from 'util/constants';

const stringArray = z.array(z.string());
const productTypesSchema = z
  .union([z.string(), z.array(z.string())])
  .transform(v => (Array.isArray(v) ? v : [v])) as unknown as typeof stringArray;
const productSubtypesSchema = z
  .union([z.string(), z.array(z.string())])
  .transform(v => (Array.isArray(v) ? v : [v])) as unknown as typeof stringArray;
const colorsSchema = z
  .union([z.string(), z.array(z.string())])
  .transform(v => (Array.isArray(v) ? v : [v])) as unknown as typeof stringArray;
const sizesSchema = z
  .union([z.string(), z.number(), z.array(z.string().or(z.number()))])
  .transform(v => (Array.isArray(v) ? v : [v])) as unknown as typeof stringArray;

const numbersArray = z.array(z.number());
const brandIdsSchema = z
  .union([z.number(), z.array(z.number())])
  .transform(v => (Array.isArray(v) ? v : [v])) as unknown as typeof numbersArray;

export const filtersQueryParamsSchema = z.object({
  brandIds: brandIdsSchema,
  category: z.string(),
  // TODO: Should be enum
  sortBy: z.string(),
  department: z.string(),
  condition: z.string(),
  productType: productTypesSchema,
  productSubtype: productSubtypesSchema,
  colors: colorsSchema,
  priceRange: z.tuple([z.coerce.number(), z.coerce.number()]),
  text: z.string(),
  sizes: sizesSchema,
  availability: z.enum(['all', 'hide_sold']),
  shipsTo: z.string(),
  preloved: z.boolean(),
});

export type FiltersQueryParams = z.infer<typeof filtersQueryParamsSchema>;

export function useFilterQueryParams() {
  const defaultFilters = FILTERS_DEFAULTS;
  const [params, setParams, generateNewParams] = useQueryParams(
    defaultFilters,
    filtersQueryParamsSchema
  );

  return [params, setParams, generateNewParams] as const;
}

export const DEFAULT_PRICE_RANGE = [0, 10000] as const;
export const RESETTABLE_FILTERS_DEFAULTS: Pick<
  FiltersQueryParams,
  | 'department'
  | 'productType'
  | 'productSubtype'
  | 'condition'
  | 'colors'
  | 'priceRange'
  | 'text'
  | 'brandIds'
  | 'sizes'
  | 'preloved'
  | 'shipsTo'
> = {
  text: '',
  department: '',
  productType: [],
  productSubtype: [],
  colors: [],
  condition: '',
  priceRange: [...DEFAULT_PRICE_RANGE],
  brandIds: [],
  sizes: [],
  preloved: false,
  shipsTo: '',
};
export const FILTERS_DEFAULTS: FiltersQueryParams = {
  ...RESETTABLE_FILTERS_DEFAULTS,
  category: '',
  // TODO: Should be type-checked
  sortBy: DEFAULT_SORTING,
  availability: 'all',
};

export const parseFiltersQueryParams = (search: string) => {
  return parseSearchQueryParams(search, FILTERS_DEFAULTS, filtersQueryParamsSchema);
};

export type GetListingsWithFiltersRequest = {
  brandIds?: number[];
  category?: string;
  sortBy?: string;
  department?: string;
  condition?: string;
  productType?: string[];
  productSubtype?: string[];
  colors?: string[];
  priceRange?: [number, number];
  text?: string;
  sizes?: (string | number)[];
  availability?: 'all' | 'hide_sold';
  preloved?: boolean;
  page?: number;
  perPage?: number;
  country?: string;
};

export type GetListingsRequest = {
  page?: number;
  perPage?: number;
  country?: string;
};

export const useGetSearchPageTitle = () => {
  const [searchParams] = useFilterQueryParams();
  const { data: brandData } = useGetBrandIdOptions();
  const brands = brandData?.brands || [];

  const department = searchParams?.department;
  const departmentLabel = department
    ? departmentOptions.find(d => d.value === department)?.label
    : undefined;

  const category = searchParams?.category;
  const categoryLabel = category
    ? categoryOptions.find(pt => pt.value === category)?.label
    : undefined;

  const productTypes = searchParams?.productType || [];
  const productTypeLabels = productTypes
    .map(pt => productTypeOptions.find(opt => opt.value === pt)?.label)
    .filter(Boolean);

  const productSubtypes = searchParams?.productSubtype || [];
  const productSubtypeLabels = productSubtypes
    .map(pst => productSubtypeOptions.find(opt => opt.value === pst)?.label)
    .filter(Boolean);

  const brandIds = searchParams?.brandIds || [];
  const brandLabels = brandIds.map(id => brands.find(b => b.id === id)?.name).filter(Boolean);

  let title = '';
  if (brandLabels.length === 1) {
    title = `${brandLabels[0]} `;
  }
  if (productSubtypeLabels.length === 1) {
    title += `${productSubtypeLabels[0]} `;
  } else if (productTypeLabels.length === 1) {
    title += `${productTypeLabels[0]} `;
  }

  let additionalText = '';
  if (departmentLabel) {
    additionalText += departmentLabel.endsWith('s')
      ? `${departmentLabel}' `
      : `${departmentLabel}'s `;
  }
  if (searchParams?.condition === ConditionType.SampleSale) {
    additionalText += 'Samples ';
  } else if (searchParams?.preloved) {
    additionalText += 'Preloved ';
  }

  if (categoryLabel) {
    additionalText += categoryLabel;
  } else if (searchParams?.condition !== ConditionType.SampleSale) {
    additionalText += 'Items';
  }
  return [title, additionalText]
    .filter(v => Boolean(v.trim()))
    .join(' - ')
    .replace(/\s+/g, ' ')
    .trim();
};
