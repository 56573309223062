import React, { useEffect, useState } from 'react';
import { Field, Form, useForm, useFormState } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { GetShippingRatesParams, useGetShippingRates } from '../CheckoutPage.hooks';
import { PrimaryButton } from 'components';
import { AddressFormFields } from 'containers/SettingsPage/ProfileAddressesForm/components/AddressForm/AddressForm';
import { useGeolocation } from 'hooks/useGeolocation';
import { EMAIL_RE } from 'util/validators';

type P = {
  showAddressesFrom: 'GB' | 'EU' | 'all';
  billing?: boolean;
  isLoadingCurrentUser: boolean;
  setRates: (rates: any) => void;
  setOrderData: (orderData: any) => void;
  discountCodes: string[];
  onAddressSaved: () => void;
  onAddressEdit: () => void;
};

export const CheckoutAddressFieldsGuest: React.FC<P> = ({
  showAddressesFrom,
  billing,
  setRates,
  isLoadingCurrentUser,
  setOrderData,
  discountCodes,
  onAddressSaved,
  onAddressEdit,
}) => {
  const formApi = useForm();
  const formState = useFormState();
  const params = useParams<{ id: string }>();
  const listingId = params.id;
  const { userGeolocation } = useGeolocation();
  const [shouldFetchRates, setShouldFetchRates] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);

  const fieldPrefix = billing ? 'billing' : '';
  const fieldName = billing ? 'billingAddressId' : 'shippingAddressId';

  const address = formState.values;
  const canFetchShippingRates = Boolean(address.country && address.city && address.zip);

  let getShippingRatesRequest: GetShippingRatesParams = {
    listingId,
    userGeolocation,
    address: {
      country: address.country,
      city: address.city,
      street1: address.street1,
      zip: address.zip,
      email: address.email,
      fullName: address.fullName,
      phoneNumber: address.phoneNumber,
    },
  };

  const {
    data: shippingRatesData,
    isSuccess,
    isInitialLoading: isLoadingRates,
    error: fetchError,
  } = useGetShippingRates(getShippingRatesRequest, {
    enabled: canFetchShippingRates && shouldFetchRates && !billing,
  });

  useEffect(() => {
    if (isSuccess && shippingRatesData && shouldFetchRates) {
      setRates(shippingRatesData);
      setShouldFetchRates(false);
    }
  }, [isSuccess, shippingRatesData, setRates, shouldFetchRates]);

  if (isLoadingCurrentUser) {
    return <div>Loading user data...</div>;
  }

  const handleSave = async values => {
    for (const key in values) {
      formApi.change(`${fieldPrefix}${key}`, values[key]);
    }
    if (!billing) {
      setOrderData(prevOrderData => ({
        ...prevOrderData,
        shippingAddress: values,
        shippingToCountry: values.country,
        discountCodes,
      }));
    } else {
      setOrderData(prevOrderData => ({
        ...prevOrderData,
        billingAddress: values,
      }));
    }
    setShouldFetchRates(true);
    setIsEditMode(false);
    onAddressSaved();
  };

  const handleEdit = () => {
    setIsEditMode(true);
    onAddressEdit();
    if (!billing) {
      setRates([]);
      setOrderData(prevOrderData => ({
        ...prevOrderData,
        selectedRateId: '',
      }));
    }
  };

  const isFormValid = values => {
    const isEmailValid = EMAIL_RE.test(values.email);

    const requiredFields = [
      'fullName',
      'street1',
      'city',
      'zip',
      'country',
      'email',
      'phoneNumber',
    ];
    return (
      requiredFields.every(field => values[field] && values[field].trim() !== '') && isEmailValid
    );
  };

  return (
    <>
      <Field name={fieldName}>{props => <input hidden {...props.input} />}</Field>
      <Form onSubmit={handleSave} initialValues={{ validate: true }}>
        {({ handleSubmit, values }) => (
          <div style={{ display: 'grid', gap: 24 }}>
            <AddressFormFields
              disabled={!isEditMode || isLoadingRates}
              isGuest={true}
              showAddressesFrom={showAddressesFrom}
            />
            {isEditMode ? (
              <PrimaryButton
                type="submit"
                inProgress={isLoadingRates}
                onClick={handleSubmit}
                disabled={!isFormValid(values)}
              >
                Save and continue
              </PrimaryButton>
            ) : (
              <PrimaryButton onClick={handleEdit}>Edit</PrimaryButton>
            )}
          </div>
        )}
      </Form>
    </>
  );
};
