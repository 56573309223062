import { getProcess, resolveLatestProcessName } from '../../transactions/transaction';
import { formatMoney } from '../../util/currency';
import { FormattedMessage } from '../../util/reactIntl';
import css from './OrderBreakdown.module.css';

interface LineItemTotalPriceProps {
  transaction: any;
  isProvider: boolean;
  intl: any;
  totalPrice: any;
  isGuest: boolean;
}

const LineItemTotalPrice: React.FC<LineItemTotalPriceProps> = props => {
  const { transaction, isProvider, intl, totalPrice: propsTotalPrice, isGuest } = props;

  let totalLabel;
  let totalPrice;

  if (isGuest) {
    totalLabel = <FormattedMessage id="OrderBreakdown.total" />;
    totalPrice = propsTotalPrice;
  } else {
    const processName = resolveLatestProcessName(transaction?.attributes?.processName);

    if (!processName) {
      return null;
    }

    const process = getProcess(processName);
    const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
    const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);

    let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
    if (isCompleted) {
      providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
    } else if (isRefunded) {
      providerTotalMessageId = 'OrderBreakdown.providerTotalRefunded';
    }

    totalLabel = isProvider ? (
      <FormattedMessage id={providerTotalMessageId} />
    ) : (
      <FormattedMessage id="OrderBreakdown.total" />
    );

    totalPrice = transaction.attributes.payinTotal;
  }

  const formattedTotalPrice = formatMoney(intl, totalPrice);

  return (
    <div className={css.lineItemTotal}>
      <div className={css.totalLabel}>{totalLabel}</div>
      <div className={css.totalPrice}>{formattedTotalPrice}</div>
    </div>
  );
};

export default LineItemTotalPrice;
