import { useMutation, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import css from './AddToFavoritesButton.module.css';
import { IconHeart, IconHeartFilled } from 'assets/icons';
import { fetchCurrentUser } from 'ducks/user.duck';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { AnalyticsEvent } from 'util/analytics-events';
import { post } from 'util/httpsClient';
import { useSdk } from 'util/sdkContext';

type P = {
  className?: string;
  listing: any;
};

const AddToFavoritesButton: React.FC<P> = props => {
  const { className, listing } = props;

  const { currentUser } = useCurrentUser();
  const listingId = listing?.id?.uuid;
  const isOwnListing = listing?.author?.id?.uuid === currentUser?.id?.uuid;
  const isFavourited =
    currentUser?.attributes?.profile?.privateData?.favoriteListingsArray?.includes(listingId);

  const { mutate: toggleFavourite, isLoading } = useToggleFavourite();
  const Icon = isFavourited ? IconHeartFilled : IconHeart;

  const history = useHistory();

  if (isOwnListing) {
    return null;
  }

  return (
    <div
      className={classNames(css.favButton, className)}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();

        if (!currentUser?.id?.uuid) {
          return history.push('/login');
        }
        if (!listingId) {
          return null;
        }
        if (!isLoading) {
          toggleFavourite(listingId);
        }
      }}
    >
      <Icon />
    </div>
  );
};

export default AddToFavoritesButton;

const useToggleFavourite = () => {
  const { currentUser } = useCurrentUser();
  const sdk = useSdk();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (listingId: string) => {
      const currentFavoritesListings =
        currentUser?.attributes?.profile?.privateData?.favoriteListingsArray || [];
      const isFavourited = currentFavoritesListings.includes(listingId);

      if (!isFavourited) {
        post({
          path: '/events',
          body: { event: AnalyticsEvent.AddToWishlist, params: { listingId } },
        });
      }

      return sdk.currentUser.updateProfile({
        privateData: {
          favoriteListingsArray: !isFavourited
            ? [...currentFavoritesListings, listingId]
            : currentFavoritesListings.filter(id => id !== listingId),
        },
      });
    },
    onSuccess: () => {
      dispatch(fetchCurrentUser());
    },
    onSettled: () => {
      queryClient.invalidateQueries(['favoriteListings']);
    },
    meta: { name: 'toggleFavourite' },
  });
};
