import { useParams } from 'react-router-dom';

import { CheckboxInputs } from '../CheckoutCheckboxInputs/CheckoutCheckboxInputs';
import { DiscountCodeInput } from '../CheckoutDiscountCodeInput/CheckoutDiscountCodeInput';
import css from './CheckoutSummary.module.css';
import { Modal, NamedLink, PrimaryButton } from 'components';
import IconSpinner from 'components/IconSpinner/IconSpinner';
import OrderBreakdown from 'components/OrderBreakdown/OrderBreakdown';
import OrderSummary from 'components/OrderSummary/OrderSummary';
import { useGetListing } from 'hooks/api/listings/useGetListing';
import { useGuestLineItems } from 'hooks/api/transactions/useGetLineItems';
import { useGeolocation } from 'hooks/useGeolocation';
import { ensureListing } from 'util/data';
import { createListingSlug } from 'util/urlHelpers';

const CheckoutSummary = ({
  transaction,
  orderData,
  setOrderData,
  children,
  onApply,
  onRemove,
  isLoadingSpeculatedTransaction,
  discountCodeState,
  setDiscountCodeState,
  isGuest,
}) => {
  const { id: listingId } = useParams<{ id: string }>();
  const { data: listingData } = useGetListing(listingId);
  const listing = ensureListing(listingData);
  const { userGeolocation } = useGeolocation();

  const { data: guestLineItems, isLoading: isLoadingGuestLineItems } = useGuestLineItems({
    listingId,
    rateId: orderData.selectedRateId,
    userGeolocation,
    shippingToCountry: orderData.shippingAddress?.country,
    discountCodes: orderData.discountCodes,
  });

  const missingStripeAccount = listing?.attributes?.metadata?.sellerMissingStripeAccount;

  const isLoading = isGuest ? isLoadingGuestLineItems : isLoadingSpeculatedTransaction;

  const lineItems = isGuest ? guestLineItems?.lineItems : transaction?.attributes.lineItems;

  const total = isGuest ? guestLineItems?.payinTotal : transaction?.attributes.payinTotal;

  return (
    <div className={css.root}>
      <Modal open={missingStripeAccount} showCloseButton={false} title={'Whoops'}>
        <div>
          Seller's profile isn't complete, and you can't order this item at the moment. We'll notify
          them immediately, so please try again later.
        </div>
        <Modal.Footer>
          <NamedLink
            name="ListingPage"
            params={{ id: listingId, slug: createListingSlug(listing) }}
          >
            <PrimaryButton>Go back to listing</PrimaryButton>
          </NamedLink>
        </Modal.Footer>
      </Modal>
      {listing.id && <OrderSummary currentListing={listing} />}
      <DiscountCodeInput
        onApply={onApply}
        onRemove={onRemove}
        discountCodeState={discountCodeState}
        setDiscountCodeState={setDiscountCodeState}
      />
      {discountCodeState.error && <p style={{ color: 'red' }}>{discountCodeState.error}</p>}

      {!isGuest && <CheckboxInputs orderData={orderData} setOrderData={setOrderData} />}
      {isLoading ? (
        <div className={css.loadingSpinner}>
          <IconSpinner />
        </div>
      ) : (
        lineItems &&
        lineItems.length > 0 && (
          <OrderBreakdown
            userRole="customer"
            lineItems={lineItems}
            total={total}
            isGuest={isGuest}
            transaction={isGuest ? null : transaction}
          />
        )
      )}
      <div className={css.chargeDisclaimer}>
        You will only be charged if your request is accepted within seven days by the seller of the
        item.
      </div>
      {children}
    </div>
  );
};

export default CheckoutSummary;
