import { z } from 'zod';

const envSchema = z.object({
  NODE_ENV: z.enum(['development', 'production', 'test']),
  REACT_APP_INTERCOM_APP_ID: z.string(),
  REACT_APP_LOQATE_API_KEY: z.string(),
  REACT_APP_STRIPE_PUBLISHABLE_KEY: z.string(),
  REACT_APP_GOOGLE_ANALYTICS_ID: z.string().optional(),
  REACT_APP_SHARETRIBE_SDK_CLIENT_ID: z.string(),
  REACT_APP_GROWTHBOOK_API_HOST: z.string(),
  REACT_APP_GROWTHBOOK_CLIENT_KEY: z.string(),
  REACT_APP_GUEST_ACCOUNT_PASSWORD: z.string(),
});

const parsedEnv = envSchema.parse(process.env);

const INTERCOM_APP_ID = parsedEnv.REACT_APP_INTERCOM_APP_ID;
const LOQATE_API_KEY = parsedEnv.REACT_APP_LOQATE_API_KEY;
const NODE_ENV = parsedEnv.NODE_ENV;
const STRIPE_PUBLISHABLE_KEY = parsedEnv.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const GOOGLE_ANALYTICS_ID = parsedEnv.REACT_APP_GOOGLE_ANALYTICS_ID;
const SHARETRIBE_SDK_CLIENT_ID = parsedEnv.REACT_APP_SHARETRIBE_SDK_CLIENT_ID;
const GROWTHBOOK_API_HOST = parsedEnv.REACT_APP_GROWTHBOOK_API_HOST;
const GROWTHBOOK_CLIENT_KEY = parsedEnv.REACT_APP_GROWTHBOOK_CLIENT_KEY;
const GUEST_ACCOUNT_PASSWORD = parsedEnv.REACT_APP_GUEST_ACCOUNT_PASSWORD;

export {
  GOOGLE_ANALYTICS_ID,
  GROWTHBOOK_API_HOST,
  GROWTHBOOK_CLIENT_KEY,
  GUEST_ACCOUNT_PASSWORD,
  INTERCOM_APP_ID,
  LOQATE_API_KEY,
  NODE_ENV,
  SHARETRIBE_SDK_CLIENT_ID,
  STRIPE_PUBLISHABLE_KEY,
};
