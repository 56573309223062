import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, H1, H2, Modal, NamedLink, PrimaryButton } from '..';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import ProductInfoSection from './components/ProductInfoSection/ProductInfoSection';
import ExpressCheckout from './ExpressCheckout';
import ExpressCheckoutGuest from './ExpressCheckoutGuest';
import css from './OrderPanel.module.css';
import { DateOfBirthForm } from 'components/OrderPanel/DateOfBirthForm';
import {
  categoryOptions,
  productTypeOptions,
} from 'containers/EditListingPage/EditListingWizard/EditListingCategoryAndSize/productTypeDefinitions';
import { sendInquiry } from 'containers/ListingPage/ListingPage.duck';
import { FILTERS_DEFAULTS, FiltersQueryParams } from 'containers/SearchPage/SearchPage.hooks';
import { useRouteConfiguration } from 'context/routeConfigurationContext';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { cn } from 'util/cn';
import { pathByRouteName } from 'util/routes';
import { stringify } from 'util/urlHelpers';
import { isGuestUser } from 'util/user';
import { useGeolocation } from 'hooks/useGeolocation';
import { isEUCountry } from 'translations/countryCodes';

const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

type OrderPanelProps = {
  rootClassName?: string | null;
  className?: string | null;
  titleClassName?: string | null;
  listing?: any;
  isOwnListing?: boolean;
  author?: any;
  authorLink?: ReactNode;
  title: ReactNode | string;
  subTitle?: ReactNode | string;
  status?: ReactNode | string;
  onManageDisableScrolling: () => void;
  monthlyTimeSlots?: object;
  lineItems?: any[];
  fetchLineItemsInProgress: boolean;
  fetchLineItemsError?: Error;
  marketplaceCurrency: string;
  dayCountAvailableForBooking: number;
  marketplaceName: string;
  editParams?: any;
  listingSlug?: string;
};

const OrderPanel: React.FC<OrderPanelProps> = props => {
  const {
    rootClassName,
    className,
    listing,
    isOwnListing,
    title,
    author,
    lineItems,
    marketplaceCurrency,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    listingSlug,
    editParams,
    status,
  } = props;

  const [showDobModal, setShowDobModal] = useState(false);
  const intl = useIntl();
  const { userGeolocation } = useGeolocation();

  const price = listing?.attributes?.price;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = currentStock === 0;

  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};

  const classes = classNames(rootClassName, className);

  const brandName = listing?.attributes?.publicData.brandName;
  const brandId = listing?.attributes?.publicData.brandId;

  const category = listing?.attributes?.publicData.category;
  const categoryLabel =
    category && categoryOptions.find(option => option.value === category)?.label;
  const useProductType = category === 'clothing';
  const productType = listing?.attributes?.publicData.productType;
  const productTypeLabel = productTypeOptions.find(option => option.value === productType)?.label;

  const priceLabel = price ? formatMoney(intl, price) : '';
  const priceWhenBought = listing?.attributes?.publicData?.priceWhenBought;
  const originalPriceLabel =
    priceWhenBought &&
    priceWhenBought.amount > price.amount &&
    formatMoney(intl, new Money(priceWhenBought.amount, priceWhenBought.currency));

  const [inquiryModalOpen, setInquiryModalOpen] = useState(false);
  const sendInquiry = useSendInquiry();

  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const routeConfiguration = useRouteConfiguration();
  const loggedIn = currentUser.id?.uuid;

  const isGuest = isGuestUser(currentUser);
  const isUserFromEu = isEUCountry(userGeolocation);

  const checkoutPage = isGuest ? 'CheckoutPageGuest' : 'CheckoutPage';

  const handleInquiryClick = () => {
    if (loggedIn) {
      setInquiryModalOpen(true);
    } else {
      history.push(pathByRouteName('LoginPage', routeConfiguration));
    }
  };

  const isApplePayEnabled = Boolean(window.ApplePaySession);
  const CtaButton = () => {
    if (currentUser.id && !currentUser.attributes.profile.privateData?.dateOfBirth) {
      return (
        <PrimaryButton onClick={() => setShowDobModal(true)}>
          <FormattedMessage id="ProductOrderForm.ctaButton" />
        </PrimaryButton>
      );
    }

    return isApplePayEnabled ? (
      isGuest ? (
        <ExpressCheckoutGuest listingId={listing?.id?.uuid} listing={listing} />
      ) : (
        <ExpressCheckout listingId={listing?.id?.uuid} listing={listing} />
      )
    ) : (
      <NamedLink name={checkoutPage} params={{ id: listing?.id?.uuid, slug: listingSlug }}>
        <PrimaryButton className="w-full h-full">
          <FormattedMessage id="ProductOrderForm.ctaButton" />
        </PrimaryButton>
      </NamedLink>
    );
  };

  const redirectToCheckout = () => {
    setShowDobModal(false);
    history.push(
      pathByRouteName(checkoutPage, routeConfiguration, {
        id: listing?.id?.uuid,
        slug: listingSlug,
      })
    );
  };

  const showCta = Boolean(!isOutOfStock && listing?.id?.uuid && listingSlug);
  return (
    <div className={classes}>
      <div className={css.topInfoSection}>
        {Boolean(status) && <div className={css.status}>{status}</div>}
        {brandName && (
          <div className="inline-block">
            <NamedLink
              className="inline"
              name="SearchPage"
              to={{
                search: stringify({
                  ...FILTERS_DEFAULTS,
                  brandIds: [brandId],
                } as FiltersQueryParams),
              }}
            >
              <h2 className={cn(css.brandName, 'inline')}>{brandName}</h2>
            </NamedLink>
            <span className="font-syne text-0 mx-1">·</span>
            <NamedLink
              className="inline"
              name="SearchPage"
              to={{
                search: stringify({
                  ...FILTERS_DEFAULTS,
                  [useProductType ? 'productType' : 'category']: useProductType
                    ? productType
                    : category,
                  brandIds: [brandId],
                } as FiltersQueryParams),
              }}
            >
              <h2 className={cn(css.brandName, 'inline')}>
                {brandName} {useProductType ? productTypeLabel : categoryLabel}
              </h2>
            </NamedLink>
          </div>
        )}
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: any; className: any; }' is not a... Remove this comment to see the full error message */}
        <H1 className={css.heading}>{title}</H1>
        <div className={css.prices}>
          <span>{priceLabel}</span>
          {originalPriceLabel && <span> {originalPriceLabel} </span>}
        </div>
        <div
          className={cn(
            'grid gap-2 !mt-6 !mb-2 ',
            !isOwnListing && showCta
              ? // Fixed pixel heights are for Stripe Express Checkout element loading hack
                'h-[98px] sm:h-[44px] grid-cols-1 grid-rows-2 sm:grid-rows-1 sm:grid-cols-2'
              : 'h-auto'
          )}
        >
          {!isOwnListing ? (
            <>
              {showCta && <CtaButton />}
              <Button type="button" onClick={() => handleInquiryClick()}>
                <FormattedMessage id="OrderPanel.ctaButtonMessageSeller" />
              </Button>
              <Modal
                open={showDobModal}
                title="Please enter your date of birth"
                onOpenChange={setShowDobModal}
              >
                <DateOfBirthForm onDateOfBirthUpdated={redirectToCheckout} />
              </Modal>
              <Modal
                open={inquiryModalOpen}
                onOpenChange={setInquiryModalOpen}
                title="Message seller"
              >
                <form
                  className={css.inquiryForm}
                  onSubmit={e => {
                    e.preventDefault();
                    // @ts-expect-error TS(2339) FIXME: Property 'message' does not exist on type 'EventTa... Remove this comment to see the full error message
                    sendInquiry(listing, e.target.message.value);
                    setInquiryModalOpen(false);
                  }}
                >
                  <label>
                    Content
                    <textarea name="message" autoFocus />
                  </label>
                  <PrimaryButton type="submit">Inquire</PrimaryButton>
                </form>
              </Modal>
            </>
          ) : !isOutOfStock ? (
            <NamedLink name="EditListingPage" params={editParams}>
              <PrimaryButton className="w-full">Edit listing</PrimaryButton>
            </NamedLink>
          ) : undefined}
        </div>
        <div className="text-00 grid gap-2 !mb-2">
          {isApplePayEnabled && (
            <NamedLink
              name={checkoutPage}
              className="underline font-semibold justify-self-center"
              params={{ id: listing?.id?.uuid, slug: listingSlug }}
            >
              Go to standard checkout
            </NamedLink>
          )}
          <div>
            {isUserFromEu
              ? `Buying the item blocks the value of the item plus the shipping fee, which varies depending on your location, and the buyer
            protection fee (€4.99) from your balance. If the order isn’t accepted by the seller
            within 7 days, you will get a 100% refund automatically. All payments are securely
            processed by Stripe.`
              : `Buying the item blocks the value of the item plus the shipping fee (£2.48) and the buyer
            protection fee (£4.99) from your balance. If the order isn’t accepted by the seller
            within 7 days, you will get a 100% refund automatically. All payments are securely
            processed by Stripe.`}
          </div>
        </div>
        <ProductInfoSection
          price={price}
          marketplaceCurrency={marketplaceCurrency}
          currentStock={currentStock}
          pickupEnabled={pickupEnabled}
          shippingEnabled={shippingEnabled}
          isOwnListing={isOwnListing}
          marketplaceName={marketplaceName}
          lineItems={lineItems}
          fetchLineItemsInProgress={fetchLineItemsInProgress}
          fetchLineItemsError={fetchLineItemsError}
          intl={intl}
          author={author}
          listing={listing}
          isOutOfStock={isOutOfStock}
          listingSlug={listingSlug}
        />
      </div>
    </div>
  );
};

function useSendInquiry() {
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUser();
  return (listing, message) => dispatch(sendInquiry(listing, message, currentUser));
}

export default OrderPanel;
