import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { pathByRouteName } from '../../util/routes';
import { CheckoutFormData } from './CheckoutPage';
import { useRouteConfiguration } from 'context/routeConfigurationContext';
import { useLogout } from 'hooks/api/auth';
import { useGuestAccount } from 'hooks/api/auth/useGuestAccount';
import { useGetListing } from 'hooks/api/listings/useGetListing';
import { useGuestInitiateOrder, useInitiateOrder } from 'hooks/api/transactions/useInitiateOrder';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useGeolocation } from 'hooks/useGeolocation';
import { ensureListing, ensureUser } from 'util/data';
import { get, post, put } from 'util/httpsClient';
import { isGuestUser } from 'util/user';

export const useCreatePaymentMethod = () => {
  const stripe = useStripe();
  const elements = useElements();

  return useMutation({
    mutationFn: async () => {
      if (!stripe || !elements) {
        throw new Error("Stripe.js hasn't loaded yet.");
      }

      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        throw new Error('CardElement not found. Make sure it is rendered in the component.');
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        console.error('Error creating payment method:', error);
        throw error;
      }

      if (!paymentMethod) {
        throw new Error('Payment method creation failed.');
      }

      return paymentMethod;
    },
    meta: { name: 'createPaymentMethod' },
  });
};

export const usePayItem = () => {
  const { mutateAsync: initiateOrder } = useInitiateOrder();
  const { mutateAsync: guestInitiateOrder } = useGuestInitiateOrder();
  const stripe = useStripe();
  const { userGeolocation } = useGeolocation();

  return useMutation({
    mutationFn: async ({
      listingId,
      orderData,
      paymentMethodId,
      isGuest,
    }: {
      listingId: string;
      orderData: {
        shippingAddressId: string;
        billingAddressId: string;
        selectedRateId: string;
        useNoldCredit: boolean;
        authenticate: boolean;
        discountCodes: string[];
      } & CheckoutFormData;
      amountToPay: number;
      paymentMethodId: string;
      transactionId: string | null;
      isGuest: boolean;
    }) => {
      let initiateOrderResponse;

      if (isGuest) {
        initiateOrderResponse = await guestInitiateOrder({
          rateId: orderData.selectedRateId,
          userGeolocation: userGeolocation || 'GB',
          address: {
            fullName: orderData.fullName,
            email: orderData.email || '',
            phoneNumber: orderData.phoneNumber,
            street1: orderData.street1,
            street2: orderData.street2,
            zip: orderData.zip,
            country: orderData.country,
            city: orderData.city,
            state: '',
          },
          billingAddress: {
            country: orderData.billingCountry || orderData.country,
            city: orderData.billingCity || orderData.city,
            street1: orderData.billingStreet1 || orderData.street1,
            street2: orderData.billingStreet2 || orderData.street2,
            zip: orderData.billingZip || orderData.zip,
            state: '',
            phoneNumber: orderData.billingPhoneNumber || orderData.phoneNumber,
          },
          listingId,
          discountCodes: orderData.discountCodes,
          authenticate: orderData.authenticate || false,
          isExpressCheckout: false,
          useShippingAddressForBilling: !orderData.billingCountry,
        });
      } else {
        // Initiate the order for logged-in users
        initiateOrderResponse = await initiateOrder({
          rateId: orderData.selectedRateId,
          shippingToAddressId: Number(orderData.shippingAddressId),
          billingAddressId: orderData.billingAddressId ? Number(orderData.billingAddressId) : null,
          userGeolocation: userGeolocation || 'GB',
          listingId,
          discountCodes: orderData.discountCodes,
          useNoldCredit: orderData.useNoldCredit,
          isSpeculative: false,
          authenticate: orderData.authenticate,
          isExpressCheckout: false,
        });
      }

      const paymentIntent = await post({
        path: '/payment-intents',
        body: {
          paymentMethodId: paymentMethodId,
          transactionId: initiateOrderResponse.id.uuid || initiateOrderResponse.id,
        },
      });

      if (paymentIntent.status === 'requires_action') {
        const stripeResponse = await stripe?.confirmCardPayment(paymentIntent.client_secret);
        if (stripeResponse?.error) {
          throw new Error(stripeResponse.error.message);
        }
      }

      return { paymentIntentId: paymentIntent.id, transactionResponseId: initiateOrderResponse.id };
    },
    meta: { name: 'payItem' },
  });
};

export const useCreateStripeAccountIfNotExistent = (shouldRedirect, isGuest) => {
  try {
    const { currentUser } = useCurrentUser();
    const { userGeolocation } = useGeolocation();
    const {
      mutate: createStripeAccount,
      isLoading: isCreatingStripeAccount,
      error: stripeAccountCreateError,
    } = useMutation({
      mutationFn: async () => {
        return post({ path: '/users/stripe', body: { userGeolocation } });
      },
      meta: { name: 'createStripeAccountIfNotExistent' },
    });

    useEffect(() => {
      if (
        !shouldRedirect &&
        currentUser?.attributes.profile.privateData &&
        !isGuest &&
        !currentUser?.attributes.profile.privateData?.stripeId
      ) {
        createStripeAccount();
      }
    }, [
      createStripeAccount,
      currentUser?.attributes.profile.privateData,
      currentUser?.attributes.profile.privateData?.stripeId,
      shouldRedirect,
      isGuest,
    ]);

    return { isCreatingStripeAccount, stripeAccountCreateError };
  } catch (error) {
    console.error('Error creating stripe account: ', error);
    throw error;
  }
};

export const useConfirmPayment = () => {
  return useMutation({
    mutationFn: async ({
      transactionId,
      paymentIntentId,
    }: {
      transactionId: string;
      paymentIntentId: string;
    }) => {
      return await put({
        path: '/payment-intents',
        body: {
          paymentIntentId,
          transactionId,
        },
      });
    },
    meta: { name: 'confirmPayment' },
  });
};

export function useRedirectToOrdersPage() {
  const { currentUser } = useCurrentUser();
  const routeConfiguration = useRouteConfiguration();

  // TODO: Type safe search params
  const successRedirect =
    pathByRouteName('ProfilePage', routeConfiguration, {
      id: currentUser?.id?.uuid || '0',
    }) + '?profileTab=orders';
  const history = useHistory();

  return useCallback(() => {
    history.push(successRedirect);
  }, [history, successRedirect]);
}

export function useShouldRedirect() {
  const { id: listingId } = useParams<{ id: string }>();
  const { currentUser } = useCurrentUser();
  const { data: listingData, isLoading } = useGetListing(listingId);
  const isGuest = isGuestUser(currentUser);

  const currentAuthor = ensureUser(ensureListing(listingData).author);
  const isOwnListing = currentUser?.id && currentAuthor?.id?.uuid === currentUser?.id?.uuid;
  const hasDateOfBirth = Boolean(currentUser?.attributes.profile.privateData?.dateOfBirth);
  const hasRequiredData = Boolean(listingId && currentAuthor.id);
  const canShowPage = hasRequiredData && !isOwnListing;

  const shouldRedirect = !isLoading && ((!isGuest && !hasDateOfBirth) || !canShowPage);
  if (shouldRedirect) {
    console.error('User is not allowed to access this page due to missing data');
  }

  return shouldRedirect;
}

export function useSubmitPaymentRequest({
  amountToPay,
  listingId,
  transactionId,
  orderData,
  isGuest,
}) {
  const { mutateAsync: createPaymentMethod } = useCreatePaymentMethod();
  const { mutateAsync: payItem } = usePayItem();
  const { mutateAsync: confirmPayment } = useConfirmPayment();
  const { mutateAsync: loginGuest } = useGuestAccount();
  const { mutateAsync: logout } = useLogout();
  const history = useHistory();

  const redirectToOrdersPage = useRedirectToOrdersPage();

  return useMutation({
    mutationFn: async (values: CheckoutFormData) => {
      try {
        const paymentMethod = await createPaymentMethod();

        if (isGuest) {
          await loginGuest({ email: values.email || '', fullName: values.fullName });
        }
        const { paymentIntentId, transactionResponseId } = await payItem({
          orderData: {
            ...values,
            shippingAddressId: values.shippingAddressId || '',
            billingAddressId: values.billingAddressId || values.shippingAddressId || '',
            selectedRateId: values.shippingRateId,
            useNoldCredit: orderData.useNoldCredit,
            authenticate: orderData.authenticate,
            discountCodes: orderData.discountCodes,
          },
          listingId,
          amountToPay,
          paymentMethodId: paymentMethod.id,
          transactionId: null,
          isGuest,
        });

        await confirmPayment({ transactionId: transactionResponseId, paymentIntentId });

        if (isGuest) {
          await logout();
          history.push('/thank-you');
        } else {
          redirectToOrdersPage();
        }
      } catch (error) {
        console.error('Error submitting payment request: ', error);
        if (isGuest) {
          await logout();
        }

        throw error;
      }
    },
    meta: { name: 'submitPaymentRequest' },
  });
}

export type GetShippingRatesParams = {
  buyerAddressId?: string;
  listingId: string;
  userGeolocation: string;
  address?: {
    zip: string;
    country: string;
    city: string;
    email?: string;
    fullName?: string;
    phoneNumber?: string;
    street1?: string;
  };
};

export function useGetShippingRates(
  params: GetShippingRatesParams,
  opts: { enabled: boolean } = { enabled: true }
) {
  return useQuery({
    enabled: opts.enabled,
    queryKey: getShippingRatesQueryKey(params),
    staleTime: 60 * 1000,
    cacheTime: 60 * 1000,
    queryFn: opts.enabled ? getShippingRatesQueryFn(params) : undefined,
    meta: {
      errorMessage: 'Failed to fetch shipping rates',
    },
  });
}

export function getShippingRatesQueryKey(params: GetShippingRatesParams) {
  return ['shippingRates', params];
}

export function getShippingRatesQueryFn(params: GetShippingRatesParams) {
  if (!params.buyerAddressId && !params.address) {
    throw new Error('Missing address or buyerAddressId');
  }

  return async () =>
    get({
      path: `/orders/rates?${new URLSearchParams({
        listingId: params.listingId,
        ...(params.buyerAddressId && { buyerAddressId: params.buyerAddressId }),
        ...params.address,
        location: params.userGeolocation,
      }).toString()}`,
    });
}
