import { useMutation, useQuery } from '@tanstack/react-query';

import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { APIError, post } from 'util/httpsClient';

export interface InitiateOrderRequest {
  rateId: string;
  shippingToAddressId: number;
  shippingToCountry?: string;
  billingAddressId: number | null;
  userGeolocation: string;
  listingId?: string;
  shippingMethodId?: string;
  discountCodes?: string[];
  useNoldCredit: boolean;
  isSpeculative: boolean;
  authenticate: boolean;
  isExpressCheckout: boolean;
  temporaryRateId?: string;
}

export interface GuestInitiateOrderRequest {
  rateId: string;
  userGeolocation: string;
  address: {
    fullName: string;
    email: string;
    phoneNumber: string;
    street1: string;
    street2?: string;
    state: string;
    zip: string;
    country: string;
    city: string;
  };
  billingAddress: {
    country: string;
    city: string;
    street1: string;
    street2?: string;
    zip: string;
    state?: string;
    phoneNumber: string;
  };
  listingId: string;
  shippingMethodId?: string;
  discountCodes?: string[];
  authenticate: boolean;
  isExpressCheckout: boolean;
  useShippingAddressForBilling: boolean;
}

export const useInitiateOrder = () => {
  return useMutation({
    mutationFn: (request: InitiateOrderRequest) => {
      if (!request.shippingToAddressId && !request.shippingToCountry) {
        throw new Error('shippingToAddressId or shippingToCountry required');
      }
      return post({
        path: '/orders/initiate',
        body: request,
      });
    },
    onError: (error: any, variables: InitiateOrderRequest) => {
      console.error(error, 'initiate-order-failed', { variables });
      throw error;
    },
    retry: false,
    meta: { name: 'initiateOrder' },
  });
};

export const useGuestInitiateOrder = () => {
  return useMutation({
    mutationFn: async (request: GuestInitiateOrderRequest) => {
      try {
        const response = post({
          path: '/orders/initiate/guest',
          body: request,
        });

        return await response;
      } catch (error) {
        console.error('Order initiation failed:', error);
        throw error;
      }
    },
    onError: (error: any, variables: GuestInitiateOrderRequest) => {
      console.error(error, 'guest-initiate-order-failed', { variables });
      throw error;
    },
    retry: false,
    meta: { name: 'guestInitiateOrder' },
  });
};

export function useGetSpeculativeTransaction(request: InitiateOrderRequest) {
  const { mutateAsync: initiateOrder } = useInitiateOrder();
  const { currentUser } = useCurrentUser();
  request.isSpeculative = true;

  const queryKey = ['speculative-transaction', { request }];

  const isEnabled =
    Boolean(currentUser.id?.uuid) && (request.isExpressCheckout || Boolean(request.rateId));

  return useQuery<any, APIError>({
    enabled: isEnabled,
    queryKey,
    queryFn: () => initiateOrder(request),
    retry: false,
  });
}
