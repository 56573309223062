import { Elements, ExpressCheckoutElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeExpressCheckoutElementConfirmEvent } from '@stripe/stripe-js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

import IconSpinner from 'components/IconSpinner/IconSpinner';
import { ConditionType } from 'config/configListing';
import { stripePromise } from 'config/configStripe';
import {
  getShippingRatesQueryFn,
  useConfirmPayment,
} from 'containers/CheckoutPage/CheckoutPage.hooks';
import { useLogout } from 'hooks/api/auth';
import { useGuestAccount } from 'hooks/api/auth/useGuestAccount';
import { getGuestLineItems, useGuestLineItems } from 'hooks/api/transactions/useGetLineItems';
import { useGuestInitiateOrder } from 'hooks/api/transactions/useInitiateOrder';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useGeolocation } from 'hooks/useGeolocation';
import { EuCountryCode } from 'translations/countryCodes';
import { post } from 'util/httpsClient';
import { logger } from 'util/log';
import { isGuestUser } from 'util/user';
import ToastContent from 'components/ToasterContent/ToastContent';

type P = {
  listingId: string;
  listing: any;
};

const ExpressCheckoutGuest: React.FC<P> = ({ listingId, listing }) => {
  const [initialAmountAndCurrency, setInitialAmountAndCurrency] = useState<{
    amount: number;
    currency: string;
  }>();
  const { userGeolocation } = useGeolocation();
  const { currentUser } = useCurrentUser();
  const isGuest = useMemo(() => {
    return isGuestUser(currentUser);
  }, [currentUser]);

  const [orderData, setOrderData] = useState({
    shippingAddressId: '',
    selectedRateId: '',
    billingAddressId: null,
    discountCodes: [] as string[],
    useNoldCredit: false,
    noldCreditAmount: 0,
    deliveryMethod: '',
    authenticate: false,
    shippingToCountry: userGeolocation || 'GB',
  });

  const { data: guestLineItems, isLoading: isLoadingGuestLineItems } = useGuestLineItems({
    listingId,
    rateId: orderData.selectedRateId,
    userGeolocation: userGeolocation || 'GB',
    discountCodes: orderData.discountCodes,
    shippingToCountry: orderData.shippingToCountry,
  });

  useEffect(() => {
    if (!guestLineItems || initialAmountAndCurrency) {
      return;
    }

    let amount, currency;
    if (guestLineItems) {
      amount = guestLineItems.payinTotal.amount;
      currency = guestLineItems.payinTotal.currency.toLowerCase();
    }

    if (amount && currency) {
      setInitialAmountAndCurrency({ amount, currency });
    }
  }, [initialAmountAndCurrency, guestLineItems, isGuest]);

  if (!initialAmountAndCurrency || !listing) {
    return (
      <div className="h-[44px] bg-black rounded-full grid place-items-center text-white">
        <IconSpinner />
      </div>
    );
  }

  return (
    <div className="relative">
      {
        <div className="absolute inset-[0px] h-[44px] bg-black rounded-full grid place-items-center text-white">
          <IconSpinner />
        </div>
      }
      <Elements
        stripe={stripePromise}
        options={{
          mode: 'payment',
          amount: initialAmountAndCurrency!.amount,
          currency: initialAmountAndCurrency!.currency,
          capture_method: 'manual',
          appearance: {
            variables: {
              borderRadius: '50%',
            },
          },
        }}
      >
        <ExpressCheckoutGuestHelper
          listingId={listingId}
          listing={listing}
          orderData={orderData}
          setOrderData={setOrderData}
          isLoadingGuestLineItems={isLoadingGuestLineItems}
        />
      </Elements>
    </div>
  );
};

const ExpressCheckoutGuestHelper: React.FC<{
  listingId: string;
  listing: any;
  orderData: any;
  setOrderData: React.Dispatch<React.SetStateAction<any>>;
  isLoadingGuestLineItems: boolean;
}> = ({ listingId, listing, orderData, setOrderData, isLoadingGuestLineItems }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [shippingToCountry, setShippingToCountry] = useState('');
  const { mutateAsync: loginGuest } = useGuestAccount();
  const { mutateAsync: guestInitiateOrder } = useGuestInitiateOrder();
  const { mutateAsync: confirmPayment } = useConfirmPayment();
  const { mutateAsync: logout } = useLogout();
  const { userGeolocation } = useGeolocation();
  const history = useHistory();

  const updateLineItems = useCallback(
    async (rateId, country) => {
      try {
        const updatedGuestLineItems = await getGuestLineItems({
          listingId,
          rateId,
          userGeolocation: userGeolocation || 'GB',
          shippingToCountry: country,
          discountCodes: orderData.discountCodes,
        });
        const total = updatedGuestLineItems.payinTotal;
        const lineItems = updatedGuestLineItems.lineItems;

        const stripeLineItems = lineItems
          .filter(item => item.includeFor?.includes('customer'))
          .map(item => ({
            name: item.code
              .replace('line-item/', '')
              .replaceAll('-', ' ')
              .replace(/\b(\w)/g, function (firstLetter) {
                return firstLetter.toUpperCase();
              }),
            amount: item.unitPrice.amount,
          }));

        return { total, stripeLineItems };
      } catch (error) {
        console.error('Failed to update line items', error);
        throw error;
      }
    },
    [listingId, orderData.discountCodes, userGeolocation]
  );

  if (!elements || !stripe) {
    return null;
  }

  const isSampleSale = listing.attributes.publicData.condition === ConditionType.SampleSale;
  const allowedShippingCountries = ['GB', ...(isSampleSale ? Object.values(EuCountryCode) : [])];

  return (
    <ExpressCheckoutElement
      options={{
        paymentMethods: {
          applePay: 'always',
          amazonPay: 'never',
          paypal: 'never',
          link: 'never',
          googlePay: 'never',
        },
      }}
      onLoadError={error => {
        console.error('ExpressCheckoutGuestElement failed to load:', error);
      }}
      onClick={async handler => {
        if (isLoadingGuestLineItems) {
          return;
        }

        handler.resolve({
          emailRequired: true,
          lineItems: [],
          shippingAddressRequired: true,
          billingAddressRequired: true,
          phoneNumberRequired: true,
          allowedShippingCountries,
          shippingRates: [
            {
              id: 'none',
              displayName: 'Enter an address',
              amount: 0,
            },
          ],
        });
      }}
      onShippingRateChange={async event => {
        try {
          if (isLoadingGuestLineItems) {
            return;
          }
          setOrderData(prev => ({
            ...prev,
            selectedRateId: event.shippingRate.id,
            shippingToCountry,
          }));
          const { total, stripeLineItems } = await updateLineItems(
            event.shippingRate.id,
            shippingToCountry
          );

          elements?.update({
            amount: total.amount,
            currency: total.currency.toLowerCase(),
          });
          event.resolve({
            lineItems: stripeLineItems,
          });
        } catch (error: any) {
          console.error('Failed to update shipping rate', error);
          event.reject();
        }
      }}
      onShippingAddressChange={async event => {
        try {
          if (isLoadingGuestLineItems) {
            return;
          }
          const res = await getShippingRatesQueryFn({
            listingId,
            userGeolocation,
            address: {
              zip: event.address.postal_code,
              city: event.address.city,
              country: event.address.country,
            },
          })();

          const rates = res.map(rate => ({
            id: rate.id,
            displayName: rate.name,
            amount: Math.round(parseFloat(rate.amount) * 100),
          }));
          if (rates.length === 0) {
            return event.reject();
          }

          setShippingToCountry(event.address.country);
          const { total, stripeLineItems } = await updateLineItems(
            rates[0].id,
            event.address.country
          );

          elements?.update({
            amount: total.amount,
            currency: total.currency.toLowerCase(),
          });
          event.resolve({
            shippingRates: rates,
            lineItems: stripeLineItems,
          });
        } catch (error: any) {
          console.error('Failed to update shipping address', error);
          event.reject();
        }
      }}
      onConfirm={async function (event: StripeExpressCheckoutElementConfirmEvent) {
        if (!elements) {
          throw new Error("Elements don't exist");
        }
        if (!stripe) {
          throw new Error("Stripe don't exist");
        }
        try {
          await loginGuest({
            email: event.billingDetails!.email || '',
            fullName: event.shippingAddress!.name || event.billingDetails?.name || '',
          });

          const response = await guestInitiateOrder({
            rateId: event.shippingRate!.id,
            userGeolocation: userGeolocation || 'GB',
            address: {
              fullName: event.shippingAddress!.name || '',
              email: event.billingDetails!.email || '',
              phoneNumber: event.billingDetails?.phone || '',
              street1: event.shippingAddress!.address.line1,
              street2: event.shippingAddress!.address.line2 || '',
              zip: event.shippingAddress!.address.postal_code,
              country: event.shippingAddress!.address.country,
              city: event.shippingAddress!.address.city,
              state: event.shippingAddress!.address.state || '',
            },
            billingAddress: {
              country: event.billingDetails!.address.country,
              city: event.billingDetails!.address.city,
              zip: event.billingDetails!.address.postal_code,
              street1: event.billingDetails!.address.line1,
              street2: event.billingDetails!.address.line2 || '',
              phoneNumber: event.billingDetails!.phone || '',
              state: event.billingDetails!.address.state || '',
            },
            listingId,
            discountCodes: orderData.discountCodes,
            authenticate: false,
            isExpressCheckout: true,
            useShippingAddressForBilling:
              event.shippingAddress!.address === event.billingDetails!.address,
          });

          const { error: submitError } = await elements.submit();
          if (submitError) {
            logger.exception(submitError);
            throw submitError;
          }

          const { error, confirmationToken } = await stripe.createConfirmationToken({
            elements,
          });
          if (error) {
            logger.exception(error);
            throw error;
          }

          const paymentIntent = await post({
            path: '/payment-intents',
            body: {
              transactionId: response.id.uuid || response.id,
              confirmationToken: confirmationToken.id,
            },
          });

          await confirmPayment({
            transactionId: response.id.uuid || response.id,
            paymentIntentId: paymentIntent.id,
          });

          history.push('/thank-you');
        } catch (error: any) {
          logger.exception(error);
          await logout();

          if (error.data.errors.length > 0 && error.data.errors[0].code === 'email-taken') {
            toast.error(
              t => (
                <ToastContent
                  messages={[
                    'User with this email already exists.',
                    'Please log in or use a different email.',
                  ]}
                  toastId={t.id}
                />
              ),
              {
                duration: Infinity,
              }
            );
          } else {
            toast.error('Failed to process payment. Please try again.');
          }

          return event.paymentFailed();
        }
      }}
    />
  );
};

export default ExpressCheckoutGuest;
