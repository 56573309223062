import React, { useEffect } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { types } from 'sharetribe-flex-sdk';

import * as validators from '../../../util/validators';
import { priceData } from '../../ListingPage/ListingPage.shared';
import { CardButton } from '../../SettingsPage/ProfileAddressesForm/components/AddressItem';
import { OrderData } from '../CheckoutPage';
import { GetShippingRatesParams, useGetShippingRates } from '../CheckoutPage.hooks';
import css from './CheckoutShippingMethodFields.module.css';
import { useConfiguration } from 'context/configurationContext';
import { useGeolocation } from 'hooks/useGeolocation';

const { Money } = types;

export const CheckoutShippingMethodFieldsGuest: React.FC<{
  orderData: OrderData;
  rates: any;
}> = ({ orderData, rates }) => {
  const config = useConfiguration();
  const intl = useIntl();
  const { userGeolocation } = useGeolocation();
  const form = useForm();
  const { values } = useFormState();

  const params = useParams<{ id: string }>();
  const listingId = params.id;
  const address = orderData.shippingAddress;

  const canFetchShippingRates = !!address;

  let getShippingRatesRequest: GetShippingRatesParams | null = null;
  if (canFetchShippingRates) {
    getShippingRatesRequest = { listingId, userGeolocation, address: address };
  }

  const {
    data,
    isSuccess,
    isInitialLoading,
    error: fetchError,
  } = useGetShippingRates(getShippingRatesRequest as GetShippingRatesParams, {
    enabled: canFetchShippingRates,
  });

  let shippingRates = (data as any[] | undefined)?.map(rate => ({
    id: rate.id,
    name: rate.name,
    description: <img style={{ height: 24 }} src={rate.providerImage} alt="shipping provider" />,
    price: { amount: Number(rate.amount) * 100, currency: rate.currency },
  }));

  if (rates) {
    shippingRates = rates.map(rate => ({
      id: rate.id,
      name: rate.name,
      description: <img style={{ height: 24 }} src={rate.providerImage} alt="shipping provider" />,
      price: { amount: Number(rate.amount) * 100, currency: rate.currency },
    }));
  }

  useEffect(() => {
    if (shippingRates?.length && !values.shippingRateId) {
      form.change('shippingRateId', shippingRates[0].id);
    }
  }, [form, shippingRates, values.shippingRateId]);

  // Hardcoding a mock Evri rate for guest users in GB when no rates are available
  if (shippingRates?.length === 0 && !isInitialLoading && !fetchError && userGeolocation === 'GB') {
    shippingRates = [
      {
        id: 'evri_shipping',
        name: 'ParcelShop Drop-Off ',
        price: { amount: 248, currency: 'GBP' },
        description: (
          <img
            style={{ height: 24 }}
            src="https://shippo-static.s3.amazonaws.com/providers/200/myHermes.png"
            alt="shipping provider"
          />
        ),
      },
    ];
  }

  let getRatesError;
  if (fetchError) {
    getRatesError = (fetchError as any).data?.errors?.some(
      (err: any) => err.type === 'AlreadyRegisteredUserError'
    )
      ? 'User with this email already exists. Please log in or use a different email.'
      : 'Error getting shipping methods.';
  }

  return (
    <Field
      name="shippingRateId"
      key={shippingRates?.map(rate => rate.id).join('')}
      validate={validators.required('')}
    >
      {props => (
        <>
          <input hidden {...props.input} />
          {isInitialLoading && <div>Loading...</div>}
          {shippingRates?.map(shippingRate => (
            <CardButton
              selected={props.input.value === shippingRate.id}
              key={shippingRate.id}
              onClick={() => props.input.onChange(shippingRate.id)}
            >
              <div className={css.shippingMethodInfo}>
                <span>{shippingRate.name}</span>
                <span>
                  {
                    priceData(
                      new Money(shippingRate.price.amount, shippingRate.price.currency),
                      config.currency,
                      intl
                    )?.formattedPrice
                  }
                </span>
                <span>{shippingRate.description}</span>
              </div>
            </CardButton>
          ))}
          {props.meta.touched && props.meta.error && (
            <span style={{ color: 'red' }}>{props.meta.error}</span>
          )}
          {!isInitialLoading && fetchError && (
            <span style={{ fontWeight: 600, color: 'red' }}>{getRatesError}</span>
          )}
          {!canFetchShippingRates && (
            <div style={{ fontWeight: 600 }}>
              Please enter a shipping address to see shipping methods.
            </div>
          )}
          {shippingRates?.length === 0 &&
            !isInitialLoading &&
            isSuccess &&
            !fetchError &&
            canFetchShippingRates && (
              <div style={{ fontWeight: 600, color: 'red' }}>
                There are no shipping methods available for your address.
              </div>
            )}
          <div style={{ color: '#666' }}>
            Taxes and duties may apply based on the origin and destination countries.
          </div>
        </>
      )}
    </Field>
  );
};
