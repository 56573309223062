import { useQuery } from '@tanstack/react-query';
import { types } from 'sharetribe-flex-sdk';

import { APIError, get } from 'util/httpsClient';

const { Money, LineItem } = types;

interface GetLineItemsResponse {
  lineItems: (typeof LineItem)[];
  payinTotal: typeof Money;
}

interface UseGuestLineItemsParams {
  listingId: string;
  rateId?: string;
  userGeolocation: string;
  shippingToCountry?: string;
  discountCodes?: string[];
}

export const getGuestLineItems = async (request: UseGuestLineItemsParams) => {
  try {
    const { listingId, rateId, userGeolocation, shippingToCountry, discountCodes } = request;
    const params = new URLSearchParams({
      listingId,
      userGeolocation,
      ...(shippingToCountry && { shippingToCountry }),
      ...(rateId && { rateId }),
      ...(discountCodes && discountCodes.length > 0 && { discountCodes: discountCodes.join(',') }),
    });

    const response = await get({
      path: `/orders/line-items?${params.toString()}`,
    });

    return response as GetLineItemsResponse;
  } catch (error: any) {
    console.error(error, 'get-guest-line-items-error', { request });
    throw error;
  }
};

export function useGuestLineItems(request: UseGuestLineItemsParams) {
  const queryKey = ['guestLineItems', { request }];

  return useQuery<GetLineItemsResponse, APIError>({
    enabled: !!request.listingId && !!request.userGeolocation,
    queryKey,
    queryFn: () => getGuestLineItems(request),
    refetchOnWindowFocus: false,
    retry: false,
  });
}
