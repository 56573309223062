import { CardElement, Elements } from '@stripe/react-stripe-js';
import { useEffect, useMemo, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { toast } from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import * as log from '../../util/log';
import { CheckoutAddressFieldsGuest } from './CheckoutAddressFields/CheckoutAddressFieldsGuest';
import { CheckoutCheckbox } from './CheckoutCheckbox/CheckoutCheckbox';
import { CheckoutFormData, OrderData } from './CheckoutPage';
import {
  useCreatePaymentMethod,
  useCreateStripeAccountIfNotExistent,
  useShouldRedirect,
  useSubmitPaymentRequest,
} from './CheckoutPage.hooks';
import css from './CheckoutPage.module.css';
import { CheckoutShippingMethodFieldsGuest } from './CheckoutShippingMethodFields/CheckoutShippingMethodFieldsGuest';
import CheckoutSummary from './CheckoutSummary/CheckoutSummary';
import { LogoNold } from 'assets/icons';
import { IconSpinner, NamedLink, NamedRedirect, Page, PrimaryButton } from 'components';
import { ConditionType } from 'config/configListing';
import { stripePromise } from 'config/configStripe';
import { LegalPageTab } from 'containers/LegalPage/LegalPage';
import { useRouteConfiguration } from 'context/routeConfigurationContext';
import { useGetListing } from 'hooks/api/listings/useGetListing';
import { useGuestLineItems } from 'hooks/api/transactions/useGetLineItems';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useIsScrollingDisabled } from 'hooks/selectors/useIsScrollingDisabled';
import { useGeolocation } from 'hooks/useGeolocation';
import { useMediaQueries } from 'hooks/useMediaQueries';
import { isEUCountry } from 'translations/countryCodes';
import { sendGa4Event } from 'util/GA4Events';
import { pathByRouteName } from 'util/routes';
import { isGuestUser } from 'util/user';

export const CHECKOUT_FORM_ID = 'checkout-form';

export type DiscountCodeState = {
  input: string;
  error: string;
  isError: boolean;
  isApplied: boolean;
};

const CheckoutPageGuest = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutPageGuestHelper />
    </Elements>
  );
};

const CheckoutPageGuestHelper = () => {
  const params = useParams<{ id: string }>();
  const listingId = params.id;
  const scrollingDisabled = useIsScrollingDisabled();
  const intl = useIntl();
  const { userGeolocation } = useGeolocation();
  const { data: listing, isLoading: isListingLoading } = useGetListing(listingId);
  const { currentUser, isLoading: isLoadingCurrentUser } = useCurrentUser();
  const [shippingRates, setShippingRates] = useState<any>(null);
  const [isAddressSaved, setIsAddressSaved] = useState(false);

  const isListingFromEu =
    !isListingLoading && isEUCountry(listing.attributes.publicData?.shippingCountry);
  const showAddressesFrom =
    !isListingLoading && listing.attributes.publicData?.condition === ConditionType.SampleSale
      ? 'all'
      : isListingFromEu
      ? 'EU'
      : 'GB';

  const isGuest = useMemo(() => {
    return isGuestUser(currentUser);
  }, [currentUser]);

  const [discountCodeState, setDiscountCodeState] = useState<DiscountCodeState>({
    input: '',
    error: '',
    isError: false,
    isApplied: false,
  });

  const [orderData, setOrderData] = useState<OrderData>({
    shippingAddressId: '',
    billingAddressId: '',
    selectedRateId: '',
    discountCodes: [],
    useNoldCredit: false,
    noldCreditAmount: 0,
    deliveryMethod: '',
    authenticate: false,
    shippingToCountry: '',
  });

  const handleSetRates = (rates: any) => {
    setShippingRates(rates);
    setOrderData(prev => ({ ...prev, selectedRateId: rates[0]?.id || '' }));
  };

  useEffect(() => {
    sendGa4Event('checkout_visited', {});
  }, []);

  const [reuseDeliveryAsBilling, setReuseDeliveryAsBilling] = useState(true);

  const handleApplyDiscountCode = async code => {
    if (!orderData.discountCodes) {
      setOrderData(prev => ({ ...prev, discountCodes: [] }));
    }

    if (orderData.discountCodes && orderData.discountCodes.includes(code)) {
      setDiscountCodeState(prev => ({
        ...prev,
        error: 'This discount code has already been applied',
      }));
      return;
    }

    setDiscountCodeState(prev => ({
      ...prev,
      error: '',
      isError: false,
      isApplied: true,
    }));

    setOrderData(prev => ({ ...prev, discountCodes: [...prev.discountCodes, code] }));
  };

  const handleRemoveDiscountCode = code => {
    setDiscountCodeState({
      error: '',
      isError: false,
      isApplied: false,
      input: '',
    });

    setOrderData(prev => ({
      ...prev,
      discountCodes: prev.discountCodes ? prev.discountCodes.filter(c => c !== code) : [],
    }));
  };

  const { error } = useCreatePaymentMethod();

  const isDesktop = useMediaQueries({ viewport: 'large' });

  const {
    data: guestLineItems,
    error: guestLineItemsError,
    isLoading: isLoadingGuestLineItems,
  } = useGuestLineItems({
    listingId,
    rateId: orderData.selectedRateId,
    userGeolocation: userGeolocation || 'GB',
    discountCodes: orderData.discountCodes,
    shippingToCountry: orderData.shippingAddress?.country || orderData.shippingToCountry,
  });

  const amountToPay = guestLineItems?.payinTotal || 0;
  const { mutateAsync: handleSubmit, isLoading: isPaying } = useSubmitPaymentRequest({
    amountToPay,
    listingId,
    transactionId: null,
    orderData,
    isGuest,
  });
  const [cardInteractionEventSent, setCardInteractionEventSent] = useState(false);
  const [cardElementFilled, setCardElementFilled] = useState(false);

  const shouldRedirect = useShouldRedirect();
  const { isCreatingStripeAccount, stripeAccountCreateError } = useCreateStripeAccountIfNotExistent(
    shouldRedirect,
    isGuest
  );

  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  if (guestLineItemsError) {
    const error = guestLineItemsError.data?.errors?.[0];
    if (error?.type === 'DiscountCodeError') {
      console.error('Failed to apply discount code:', guestLineItemsError);
      const errorMessage =
        error?.displayMessage || 'Failed to apply discount code. Please try again.';

      setDiscountCodeState(prev => ({
        ...prev,
        error: errorMessage,
        isError: true,
        isApplied: false,
      }));

      setOrderData(prev => ({
        ...prev,
        discountCodes: prev.discountCodes.slice(0, -1),
      }));
    } else if (error?.type === 'OutOfStockError') {
      console.error('Out of stock error:', guestLineItemsError);
      toast.error('Sorry, this item is no longer available', {
        duration: 10000,
      });

      const searchPagePath = pathByRouteName('SearchPage', routeConfiguration);
      history.push(`${searchPagePath}`);
    } else if (error?.type === 'ListingIsNotForSaleInEU') {
      console.error('Listing not for sale in the EU:', guestLineItemsError);
      log.error(error, 'listing-not-for-sale-in-eu', guestLineItemsError);
      toast.error(guestLineItemsError.data?.errors[0]?.displayMessage, {
        duration: 10000,
      });

      const searchPagePath = pathByRouteName('SearchPage', routeConfiguration);
      history.push(`${searchPagePath}`);
    }
  }

  if (isLoadingCurrentUser) {
    return (
      <div className={css.formLoadOverlay}>
        <IconSpinner />
      </div>
    );
  }

  // Redirect back to ListingPage if data is missing.
  // Redirection must happen before any data format error is thrown (e.g. wrong currency)
  if (shouldRedirect || stripeAccountCreateError) {
    // eslint-disable-next-line no-console
    console.error('Missing or invalid data for guest checkout, redirecting back to listing page.', {
      listingId,
    });
    if (stripeAccountCreateError) {
      toast.error('Failed to set up your stripe account. Please contact our support team.');
      log.error(stripeAccountCreateError, (stripeAccountCreateError as any).code, undefined);
    }
    return <NamedRedirect name="ListingPage" params={params} />;
  }

  return (
    <FinalForm<CheckoutFormData>
      destroyOnUnregister
      onSubmit={async values => {
        if (!cardElementFilled) {
          toast.error('Please complete the card details before submitting.');
          return;
        }

        try {
          await handleSubmit(values);
          toast.success(intl.formatMessage({ id: 'CheckoutPage.paymentSuccess' }));
        } catch (e) {
          toast.error(intl.formatMessage({ id: 'CheckoutPage.paymentFailure' }));
        }
      }}
      validate={values => {
        const errors: Record<string, string> = {};

        if (!cardElementFilled) {
          errors.cardElement = 'Please fill in your card details';
        }

        const addressFields = [
          'email',
          'fullName',
          'phoneNumber',
          'street1',
          'city',
          'zip',
          'country',
        ];
        const isAddressComplete = addressFields.every(field => !!values[field]);

        if (!isAddressComplete) {
          errors.shippingAddress = 'Please provide a complete shipping address';
        }

        if (!reuseDeliveryAsBilling) {
          const billingFields = [
            'billingFullName',
            'billingPhoneNumber',
            'billingStreet1',
            'billingCity',
            'billingZip',
            'billingCountry',
          ];
          const isBillingComplete = billingFields.every(field => values[field]);

          if (!isBillingComplete) {
            errors.billingAddress = 'Please provide a complete billing address';
          }
        }

        return Object.keys(errors).length > 0 ? errors : undefined;
      }}
      render={({ handleSubmit, values, valid }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          setOrderData(v => ({
            ...v,
            selectedRateId: values.shippingRateId,
          }));
        }, [values.shippingRateId]);

        return (
          <Page
            title={intl.formatMessage({ id: 'CheckoutPage.title' })}
            scrollingDisabled={scrollingDisabled}
            className={css.root}
          >
            <div className={css.root}>
              <div className={css.header}>
                <div className={css.maxWidthContainer}>
                  <NamedLink name="LandingPage">
                    <LogoNold />
                  </NamedLink>
                </div>
              </div>
              <div className={css.mainContainer}>
                <div className={css.main + ' ' + css.maxWidthContainer}>
                  <form id={CHECKOUT_FORM_ID} onSubmit={handleSubmit} className={css.main__inputs}>
                    {isCreatingStripeAccount && (
                      <div className={css.formLoadOverlay}>
                        <IconSpinner />
                      </div>
                    )}
                    <div className={css.section}>
                      <div className={css.section__title}>Deliver to</div>
                      <CheckoutAddressFieldsGuest
                        showAddressesFrom={showAddressesFrom}
                        setRates={handleSetRates}
                        isLoadingCurrentUser={isLoadingCurrentUser}
                        setOrderData={setOrderData}
                        discountCodes={orderData.discountCodes}
                        onAddressSaved={() => setIsAddressSaved(true)}
                        onAddressEdit={() => setIsAddressSaved(false)}
                      />
                      <CheckoutCheckbox
                        labelClassName={css.sameAsDeliveryCheckbox}
                        checked={reuseDeliveryAsBilling}
                        onCheckedChange={setReuseDeliveryAsBilling}
                        label="Use delivery address as billing address"
                      />
                    </div>
                    {reuseDeliveryAsBilling ? null : (
                      <div className={css.section}>
                        <div className={css.section__title}>Billing address</div>
                        <CheckoutAddressFieldsGuest
                          showAddressesFrom="all"
                          billing
                          setRates={handleSetRates}
                          isLoadingCurrentUser={isLoadingCurrentUser}
                          setOrderData={setOrderData}
                          discountCodes={orderData.discountCodes}
                          onAddressSaved={() => setIsAddressSaved(true)}
                          onAddressEdit={() => setIsAddressSaved(false)}
                        />
                      </div>
                    )}

                    <div className={css.section}>
                      <div className={css.section__title}>Choose a provider</div>
                      <CheckoutShippingMethodFieldsGuest
                        orderData={orderData}
                        rates={shippingRates}
                      />
                    </div>
                    <div className={css.section}>
                      <div className={css.section__title}>Payment</div>
                      <CardElement
                        className={css.cardElement}
                        options={{
                          style: {
                            base: {
                              fontFamily: 'Montserrat, sans-serif',
                              fontSmoothing: 'antialiased',
                              fontSize: '16px',
                              '::placeholder': {
                                color: '#444444',
                              },
                            },
                            invalid: {
                              color: 'red',
                              iconColor: 'red',
                            },
                          },
                          disabled: !isAddressSaved,
                        }}
                        onChange={e => {
                          setCardElementFilled(e.complete);

                          if (!cardInteractionEventSent && (e.empty === false || e.error)) {
                            sendGa4Event('checkout_payment_details_added', {
                              listing_id: listingId,
                            });
                            setCardInteractionEventSent(true);
                          }
                        }}
                      />
                      {error && <span style={{ color: 'red' }}>{(error as any)?.message}</span>}
                    </div>
                    {isDesktop && (
                      <>
                        <PrimaryButton
                          type="submit"
                          inProgress={isPaying}
                          disabled={!valid}
                          onClick={() => {
                            sendGa4Event('checkout_form_submitted', {});
                          }}
                        >
                          Request to buy
                        </PrimaryButton>
                        <AgreeToTnc />
                      </>
                    )}
                  </form>
                  <CheckoutSummary
                    transaction={null}
                    orderData={orderData}
                    setOrderData={setOrderData}
                    onApply={handleApplyDiscountCode}
                    onRemove={handleRemoveDiscountCode}
                    discountCodeState={discountCodeState}
                    setDiscountCodeState={setDiscountCodeState}
                    isLoadingSpeculatedTransaction={false}
                    isGuest={isGuest}
                  >
                    {!isDesktop && (
                      <>
                        <PrimaryButton
                          form={CHECKOUT_FORM_ID}
                          type="submit"
                          inProgress={isPaying}
                          disabled={!valid}
                          onClick={() => {
                            sendGa4Event('checkout_form_submitted', {});
                          }}
                        >
                          Request to buy
                        </PrimaryButton>
                        <AgreeToTnc />
                      </>
                    )}
                  </CheckoutSummary>
                </div>
              </div>
            </div>
          </Page>
        );
      }}
    />
  );
};

const AgreeToTnc = () => {
  return (
    <span className={css.agreeToTnc}>
      By clicking request to buy you agree to our{' '}
      <NamedLink name="LegalPage" params={{ tab: LegalPageTab.TermsAndConditions }}>
        Terms & Conditions
      </NamedLink>
    </span>
  );
};

export default CheckoutPageGuest;
